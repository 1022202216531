import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import Cookies from "js-cookie";

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('./view/home'),
    meta: {
      title: '首页',
    },
  },
  {
    name: 'subject',
    path: '/subject/:examid/:subject',
    component: () => import('./view/subject'),
    meta: {
      title: '类目',
    },
  },
  {
    name: 'examination',
    path: '/examination',
    component: () => import('./view/examination'),
    meta: {
      title: '考试',
    },
  },
  {
    name: 'result',
    path: '/result/:ehid',
    component: () => import('./view/examination/result'),
    meta: {
      title: '成绩单',
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('./view/login'),
    meta: {
      title: '登录',
    },
  },
  {
    name: 'facelogin',
    path: '/facelogin',
    component: () => import('./view/login/facelogin1'),
    meta: {
      title: '刷脸登录',
    },
  },
  {
    name: 'attestation',
    path: '/attestation',
    component: () => import('./view/user/attestation'),
    meta: {
      title: '人脸认证',
    },
  },
  {
    name: 'list',
    path: '/list/:exam:/:sid',
    component: () => import('./view/list'),
    meta: {
      title: '类目',
    },
  },
  {
    name: 'user',
    path: '/user',
    component: () => import('./view/user'),
    meta: {
      title: '会员中心',
    },
  },
  {
    name: 'cart',
    path: '/cart',
    component: () => import('./view/cart'),
    meta: {
      title: '购物车',
    },
  },
  {
    name: 'goods',
    path: '/goods',
    component: () => import('./view/goods'),
    meta: {
      title: '商品详情',
    },
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  let isAuthenticated = false;
  if (Cookies.get('uid')) {
    isAuthenticated = true
  }
  if (to.name == 'facelogin' && !isAuthenticated)
  next();
  else if (to.name !== 'login' && !isAuthenticated)
  next({ name: 'login' })
  else
  next();
});

export { router };
